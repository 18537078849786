import React, { createRef, useState, useEffect } from "react"

function Lazy(props) {
  const [showChild, setShowChild] = useState(false)

  const child = createRef()

  useEffect(() => {
    const childObserver = new IntersectionObserver(onChildIntersection, {
      rootMargin: "100px 0px",
      threshold: 0.25
    })
    function onChildIntersection(entries) {
      if (!entries || entries.length <= 0) {
        return
      }

      if (entries[0].isIntersecting) {
        setShowChild(true)
        childObserver.disconnect()
      }
    }
    if (window && "IntersectionObserver" in window) {
      if (child && child.current) {
        childObserver.observe(child.current)
      }
    } else {
      setShowChild(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [child])

  return <div ref={child}>{showChild ? props.children : undefined}</div>
}

export default Lazy
