import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import globals from "./globals"
import { Container, Grid, ContentBox, P, H2 } from "./styledcomponents"

const Image = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
`

const ImgContainer = styled.div`
  height: 100%;
  width: 100%;
  @media (max-width: ${globals.media.tablet}) {
    grid-row-start: 1;
  }
`
const TextBox = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  @media (max-width: ${globals.media.tablet}) {
    grid-row-start: 2;
  }
`

function ContentAndPicture(props) {
  const listItems = props.list
    ? props.list.map((item, index) => (
        <li key={index}>
          <P>{item}</P>
        </li>
      ))
    : undefined
  return (
    <Container backgroundColor="transparent" id={props.id}>
      <Grid
        columns={
          props.columns
            ? props.columns
            : props.imgLeft
            ? "1fr 3fr"
            : props.imgRight
            ? "3fr 1fr"
            : "1fr"
        }
        width="100%"
      >
        {props.imgLeft ? (
          <ImgContainer>
            <Image
              image={props.imgLeft.childImageSharp.gatsbyImageData}
              alt={props.h2}
            />
          </ImgContainer>
        ) : undefined}
        <TextBox>
          <ContentBox
            maxWidth={globals.media.tablet}
            textAlign="center"
            margin="0"
            padding="0 2em"
            overflow="visible"
          >
            <H2 style={props.h2Style}>{props.h2}</H2>
            {props.description ? <>{props.description}</> : undefined}
            {props.list ? (
              props.ordered ? (
                <ol>{listItems}</ol>
              ) : (
                <ul>{listItems}</ul>
              )
            ) : undefined}
            {props.description2 ? <>{props.description2}</> : undefined}
          </ContentBox>
        </TextBox>
        {props.imgRight ? (
          <ImgContainer>
            <Image
              image={props.imgRight.childImageSharp.gatsbyImageData}
              alt={props.h2}
            />
          </ImgContainer>
        ) : undefined}
      </Grid>
    </Container>
  )
}

export default ContentAndPicture
