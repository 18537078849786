import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import globals from "./globals"
import loadable from "@loadable/component"
const Carousel = loadable(() => import("@brainhubeu/react-carousel"))
import { autoplayPlugin, slidesToShowPlugin } from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

import Lazy from "./lazy"
import { ContentBox, P, H2 } from "./styledcomponents"

// transform: perspective(20em) rotateY(1deg) rotateZ(-5deg) skewY(-2deg) skewX(-1deg);

function Box({ img, trust }) {
  return (
    <ContentBox
      backgroundColor={globals.color.darkAccent}
      color={globals.color.lightShades}
      width="150px"
      height="150px;"
    >
      <P>{trust}</P>
    </ContentBox>
  )
}

function ImgBox({ img, trust }) {
  return (
    <ContentBox>
      <GatsbyImage
        image={img.childImageSharp.gatsbyImageData}
        alt={trust}
        title={trust}
      />
      <P center>{trust}</P>
    </ContentBox>
  )
}

function TrustBox(props) {
  return (
    <Lazy>
      <H2 center width="fit-content" margin="auto">
        {props.title}
      </H2>
      <Carousel
        className="topicsCarousel"
        plugins={[
          "arrows",
          "infinite",
          {
            resolve: autoplayPlugin,
            options: {
              interval: 2000,
            },
          },
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides: 4,
            },
          },
        ]}
        breakpoints={{
          640: {
            plugins: [
              "infinite",
              {
                resolve: autoplayPlugin,
                options: {
                  interval: 2000,
                },
              },
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 1,
                },
              },
            ],
          },
          1060: {
            plugins: [
              "arrows",
              "infinite",
              {
                resolve: autoplayPlugin,
                options: {
                  interval: 2000,
                },
              },
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 2,
                },
              },
            ],
          },
          1380: {
            plugins: [
              "arrows",
              "infinite",
              {
                resolve: autoplayPlugin,
                options: {
                  interval: 2000,
                },
              },
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 3,
                },
              },
            ],
          },
        }}
        animationSpeed={800}
      >
        {props.values.map(({ id, img, ...otherProps }) =>
          img ? (
            <ImgBox key={id} img={img} {...otherProps} />
          ) : (
            <Box key={id} {...otherProps} />
          )
        )}
      </Carousel>
      <ContentBox margin="0" />
    </Lazy>
  )
}

export default TrustBox
