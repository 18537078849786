import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout"
import SEO from "../components/seo"

import globals from "../components/globals"
import ContentAndPicture from "../components/content-and-picture"
import { ContentBox, FlexBox1, P, Li, H2 } from "../components/styledcomponents"
import Button from "../components/button"
import TrustBox from "../components/trusbox"
import { ContactFormKindergarten } from "../components/contactform"

const YogaSchools = ({ data }) => (
  <Layout>
    <SEO
      title="Yoga in Kindergärten und Schulen in München"
      description="Kinderyoga hilft Kids ihre Mobilität und Geschicklichkeit \
      auszubauen und ihr Selbstbewusstsein zu steigern. Informiere dich zu \
      unserem Angebot in München."
    />
    <h1>Yoga in Kindergärten und Schulen in München</h1>
    <FlexBox1 maxWidth={globals.media.tablet}>
      <P>
        Es ist immer eine Herausforderung Ruhe und Frieden in unserem
        stressvollem Leben zu finden. Gleichzeitig ist es schwierig für uns
        selbst aufzustehen, wenn die Zeiten hart sind. Daher ist es wichtig,
        Kindern frühzeitig zu zeigen, wie sie Ruhe, Entspannung und
        Selbstbestimmung finden können. Unsere Kinderyogakurse sind für dieses
        Ziel perfekt geeignet.
      </P>
    </FlexBox1>

    <ContentAndPicture
      h2="Die Philosophie von Kinderyoga"
      description={
        <div>
          <P>
            <Link to="/yoga-muenchen/">Yoga</Link> ist ein System zur
            Verbesserung der physischen und mentalen Fitness. Die Ziele und
            Philosophie von Yoga sind das erreichen des eigenen Potentials. Das
            fürht auch zum erreichen von innerem Frieden und Harmonie. Yoga
            lehrt den Kids, vor allem durch Bewegen des Körpers (Asana),
            Atemübungen (Pranayama) und Meditation (Dhyana) wie man mit
            Herausforderungen umgehen kann. Gleichzeitig erfahren die Kinder wie
            man den Körper und Geist fit und gesund hält. Durch Yoga lernen
            Kinder mit viel Spaß und Drishti: ‘Aufmerksamkeit &amp;
            Achtsamkeit’, auf eine spielerische Art und Weise physisch und
            mental im Gleichgewicht zu bleiben.
          </P>
          <P>
            Kinderyoga funktioniert ohne Leistungsdruck. Kids können ihre
            motorischen Fähigkeiten beim Yoga frei entwickeln. Unsere Yogalehrer
            helfen den Kids dabei ihren natürlichen Bewegungsrang auszuleben und
            ihre Mobilität und Geschicklichkeit auszubauen. Mobilität entsteht
            aus der Kombination von Kraft und Flexibilität. Ein großer Fokus
            wird auch auf gegenseitige Rücksichtnahme gesetzt. Dies wird
            beispielsweise speziell bei Partnerübungen geschult. Gleichzeitig
            werden Achtsamkeit und Disziplin geschult. So ist es zum Beispiel
            ein wichtiger Bestandteil der Yogastunde, dass die Kinder ihre
            eigenen Yogamatten aufräumen.
          </P>
          <Button to="/yoga-in-kindergarten-und-schulen-in-munchen/#angebot-anfordern">
            Angebot anfordern
          </Button>
        </div>
      }
      imgLeft={data.kinderyoga}
      alt="Kinderyoga bei Team Laurien &amp; Alex in München: Der Baum schult das Gleichgewicht"
    />

    <ContentAndPicture
      h2="Die Philosophie von Kinderyoga"
      description={
        <div>
          <P>
            Eine Kinderyogastunde durchläuft verschiedene Phasen. Körperliche
            Anstrengung und Entspannungsübungen wechseln sich während einer
            Stunde ab. Dieser Wechsel hilft den Kindern, ihre innere Kraft und
            Stärke zu finden und zur Ruhe zu kommen. Damit werden sie
            selbstbewusster und können in stressigen Situationen auf ihre innere
            Ruhe zurückgreifen.
          </P>
          <FlexBox1>
            <ContentBox width="100%" maxWidth="250px" padding="0">
              <GatsbyImage image={data.trust7.childImageSharp.gatsbyImageData} />
            </ContentBox>
            <P>
              Unsere Yogalehrer haben nach, der 200 Stunden Ausbildung bei
              Himalaya Yoga Valley in Goa, Indien, eine Kinderyogalehrer
              Ausbildung absolviert. Unsere Lehrer haben jahrelange Erfahrung im
              Unterrichten von Kindern und wurden von{" "}
              <a href="https://kiddingaroundyoga.com">Kidding Around Yoga</a>{" "}
              zertifiziert. Kidding Around Yoga ist eine der führenden
              Organisationen, wenn es um Kinderyoga geht.
            </P>
          </FlexBox1>

          <Button to="/yoga-in-kindergarten-und-schulen-in-munchen/#angebot-anfordern">
            Angebot anfordern
          </Button>
        </div>
      }
      imgRight={data.clean}
      alt="Kinderyoga - Fokus und Disziplin, die Kinder räumen ihre Yogamatten auf."
    />
    <ContentAndPicture
      h2="Kinderyoga in Münchner Schulen, Kindergarten"
      description={
        <div>
          <P>
            Wir bieten Kinderyoga in unserem Studio in München Feldmoching sowie
            vor Ort in Schulen und Kindergärten in München an. Die Kinder
            benötigen nur ein Handtuch oder eine Yogamatte und bequeme Kleidung.
            Vor Ort benötigen wir einen Raum oder eine Grünfläche, wo die
            gewünschte Anzahl an Kindern bequem Platz hat.
          </P>
          <H2>Kombinierte Selbstverteidigungs- und Yoga Kurse</H2>
          <P>
            Wir bieten unsere Kinderyoga Kurse auch als Kombination mit Kinder
            Selbstverteidigungskursen an. Beide Konzepte sind philosophisch sehr
            eng beieinander und ergänzen sich Perfekt.
          </P>
          <Button to="/yoga-in-kindergarten-und-schulen-in-munchen/#angebot-anfordern">
            Angebot anfordern
          </Button>
        </div>
      }
      imgLeft={data.yogaSv}
      alt="Kinderyoga in einer Kinder Kampfsport Stunde: 3 beiniger Hund im BJJ"
    />

    <ContentAndPicture
      h2="Positive Effekte von Kinderyoga"
      description={
        <div>
          <ul>
            <Li>Verbesserte körperliche Fitness und Körperbewusstsein</Li>
            <Li>Stärkt die mentale Fitness</Li>
            <Li>Fördert die Entspannung</Li>
            <Li>Stärkt den Charakter</Li>
            <Li>Verbessert das Selbstbewusstsein</Li>
            <Li>Erhöht die Konzentrationsfähigkeit</Li>
            <Li>Verbesserte (Selbst-) Disziplin</Li>
            <Li>Mehr Kreativität</Li>
            <Li>Ermöglicht sich richtig auszupowern</Li>
          </ul>
          <Button to="/yoga-in-kindergarten-und-schulen-in-munchen/#angebot-anfordern">
            Angebot anfordern
          </Button>
        </div>
      }
    />

    <TrustBox
      title="Unser Yoga- und Kampfsportangebot überzeugt:"
      values={[
        {
          id: 1,
          img: data.trust1,
          trust: "Kampfsportschule des Jahres 2020",
        },
        {
          id: 5,
          img: data.trust5,
          trust: "Yogalehrerausbildung in Indien von Lalit Kumar",
        },
        {
          id: 3,
          img: data.trust3,
          trust: "National und International erfolgreich",
        },
        {
          id: 6,
          img: data.trust6,
          trust: "BJJ Graduierung duch Cadu Francis",
        },
        {
          id: 4,
          img: data.trust4,
          trust: "Begeisterte Kunden",
        },
        {
          id: 2,
          img: data.trust2,
          trust: "IBJJF Zertifiziert",
        },
        {
          id: 7,
          img: data.trust7,
          trust: "Zertifiziert durch Kidding Around Yoga",
        },
      ]}
    />
    <ContactFormKindergarten />
  </Layout>
)

export default YogaSchools

export const query = graphql`{
  kinderyoga: file(
    relativePath: {eq: "Kindergarten/Kinderyoga-der-Baum-1024x1024.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  clean: file(
    relativePath: {eq: "Kindergarten/Kinderyoga-Kids-raeumen-auf-e1558948285419-769x1024.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  yogaSv: file(
    relativePath: {eq: "Kindergarten/Kinderyoga-im-Kampfsport-3-Beiniger-Hund-1024x576.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust1: file(
    relativePath: {eq: "Kampfsportschule-des-Jahres-Team-Laurien-Alex.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust2: file(relativePath: {eq: "IBJJF-Zerifikat-TLA-BJJ.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust3: file(relativePath: {eq: "Deutsche-Meister-BJJ-IBJJF-Kickboxen.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust5: file(
    relativePath: {eq: "Yogalehrerzertifikat-in-indien-von-Lalit-Kumar-Himalaya-Yoga-Valley.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust4: file(relativePath: {eq: "Reviews-Team-Laurien-Alex.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust6: file(
    relativePath: {eq: "Laurien-and-alex-with-Cadu-Francis-purple-belt-graduation.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust7: file(relativePath: {eq: "Kinderyoga-Zertifikat.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
}
`
